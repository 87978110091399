import React, { useEffect } from "react";
import { useState } from "react";
import { Table, Button, Link, Message } from "@arco-design/web-react";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

const EditTicket = () => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const getTicketsURL = `${baseURL}/get/pending-tickets`;
  const updateTicketsURL = `${baseURL}/update/tickets`;
  const computeHash = httpsCallable(functions, "compute_hash");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    showTotal: true,
    total: 10,
    pageSize: 10,
    current: 1,
    showJumper: true,
    sizeCanChange: true,
    pageSizeChangeResetCurrent: true,
  });
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [user, authLoading] = useAuthState(auth);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loadTickets = () => {
    if (authLoading) return;
    setLoading(true);
    computeHash({}).then((result) => {
      const data = result.data;
      const hash = data.cipherhex;
      const headers = {
        "Content-Type": "application/json",
        "x-cipherhex": hash,
      };
      axios
        .get(getTicketsURL, {
          headers: headers,
        })
        .then((res) => {
          setAllData(res.data);
          setData(res.data);
          setPagination({ ...pagination, total: res.data.length });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "job_id",
      width: 100,
      render: (col, record) => (
        <Link hoverable={false} href={record.link} target="_blank">
          {col}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 50,
      render: (col) => <div style={{ wordBreak: "keep-all" }}>{col}</div>,
      sorter: (a, b) => {
        return a.type.length - b.type.length;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      width: 300,
      render: (col) => (
        <div style={{ minWidth: 150, wordBreak: "keep-all" }}>{col}</div>
      ),
    },
    {
      title: "Posted",
      dataIndex: "posted",
      render: (col, record) => <div>{col}</div>,
      width: 260,
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      render: (col, record) => <div>{col}</div>,
      width: 200,
    },
  ];

  const onChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    setLoading(true);
    setTimeout(() => {
      setData(allData.slice((current - 1) * pageSize, current * pageSize));
      setPagination((pagination) => ({ ...pagination, current, pageSize }));
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    loadTickets();
  }, [authLoading, user]);

  const handleResolveClick = () => {
    const payload = selectedRowKeys.map((ticket_id) => {
      return { ticket_id: ticket_id };
    });
    computeHash({}).then((result) => {
      const data = result.data;
      const hash = data.cipherhex;
      const headers = {
        "Content-Type": "application/json",
        "x-cipherhex": hash,
      };
      axios
        .post(updateTicketsURL, payload, {
          headers: headers,
        })
        .then((res) => {
          if (res.status == 201 && res.data.updated == res.data.total) {
            Message.success("Tickets updated successfully!");
            loadTickets();
          } else {
            Message.error("Failed to update some or all tickets");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button type="primary" className="mb-5" onClick={handleResolveClick}>
        Resolve Selected Tickets
      </Button>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="ticket_id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
      />
    </div>
  );
};

export default EditTicket;
