import React, { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Select,
  Message,
  Link,
} from "@arco-design/web-react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import axios from "axios";
const FormItem = Form.Item;

const EditButton = ({ record, isIntern, reload }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;

  const computeHash = httpsCallable(functions, "compute_hash");

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  useEffect(() => {
    form.setFieldsValue(record);
  }, [form, record]);

  const onOk = () => {
    form
      .validate()
      .then((res) => {
        setConfirmLoading(true);

        computeHash({}).then((result) => {
          const data = result.data;
          const hash = data.cipherhex;
          const payload = [
            {
              ...res,
              id: record.id,
              link: record.link,
              type: isIntern ? "INTERN" : "FRESH_GRAD",
              ep: isIntern ? true : res.ep,
            },
          ];
          const url = `${baseURL}/update/jobs`;
          const headers = {
            "Content-Type": "application/json",
            "x-cipherhex": hash,
          };
          axios
            .post(url, payload, {
              headers: headers,
            })
            .then((resp) => {
              if (resp.status === 201) {
                Message.success(
                  "Your response has been submitted successfully!"
                );
                setVisible(false);
                form.clearFields(["reason"]);
              } else {
                Message.error("Submission failed. Please try again.");
              }
              setConfirmLoading(false);
              reload();
            })
            .catch((err) => {
              console.log(err);
              Message.error("Submission failed. Please try again.");
              setConfirmLoading(false);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button type="text" onClick={() => setVisible(true)}>
        Edit
      </Button>
      <Modal
        title="Edit Job"
        visible={visible}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
      >
        <Form
          {...formItemLayout}
          form={form}
          labelCol={{
            style: { flexBasis: 90 },
          }}
          wrapperCol={{
            style: { flexBasis: "calc(100% - 90px)" },
          }}
          requiredSymbol={false}
        >
          <FormItem label="Company" field="company">
            <div>{record.company}</div>
          </FormItem>
          <FormItem label="Title" field="title">
            <Link href={record.link} target="_blank">
              {record.title}
            </Link>
          </FormItem>
          {!isIntern && (
            <FormItem label="EP" field="ep" rules={[{ required: true }]}>
              <Select
                options={[
                  { label: "Sponsor EP", value: true },
                  { label: "No Sponsorship", value: false },
                ]}
              />
            </FormItem>
          )}
          <FormItem
            label="Avail"
            field="availability"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default EditButton;
