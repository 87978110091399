import React, { useEffect } from "react";
import { useState } from "react";
import {
  Table,
  Select,
  Typography,
  Button,
  Link,
  List,
  Input,
  Switch,
} from "@arco-design/web-react";
import Bookmark from "./Bookmark";
import ReportButton from "./ReportButton";
import axios from "axios";
import {
  updateNumOfNewGradJobs,
  updateNumOfCompanies,
} from "../../reduxSlice/userSlice";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Group from "@arco-design/web-react/es/Radio/group";
import { EpStatus } from "../../values/const";
import "./styles.css";
import { JobListItem } from "../common/MobileJobListItem";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import debounce from "lodash.debounce";

const Option = Select.Option;
const Title = Typography.Title;
const InputSearch = Input.Search;

const JobTable = ({ isIntern }) => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const searchURL = isIntern
    ? `${baseURL}/get/jobs/INTERN`
    : `${baseURL}/get/jobs/FRESH_GRAD`;

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      width: 150,
      render: (col) => <div style={{ wordBreak: "keep-all" }}>{col}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      render: (col, record) => {
        trackJob(record);
        return (
          <div style={{ minWidth: 150, wordBreak: "keep-all" }}>
            <Link href={record.link} target="_blank">
              {col}
            </Link>
          </div>
        );
      },
    },
    isIntern
      ? {}
      : {
          title: "Sponsor EP?",
          dataIndex: "ep",
          render: (col) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {col ? (
                <Typography style={{ fontSize: 20 }}>✅</Typography>
              ) : (
                <Typography style={{ fontSize: 20 }}>❌</Typography>
              )}
            </div>
          ),
          width: 100,
        },
    {
      title: "Apply Link",
      dataIndex: "availability",
      render: (col, record) => {
        trackJob(record);
        if (col) {
          return (
            <Link hoverable={false} href={record.link} target="_blank">
              <Button type="outline" status="success">
                Apply Now
              </Button>
            </Link>
          );
        } else {
          return (
            <Button type="secondary" disabled>
              Closed
            </Button>
          );
        }
      },
      width: 100,
    },
    {
      title: "Bookmark",
      dataIndex: "bookmark",
      render: (col, record) => (
        <Bookmark isBookmarked={col} jobId={record.id} reload={loadJobs} />
      ),
      width: 100,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <ReportButton record={record} isIntern={isIntern} />
      ),
      width: 10,
    },
  ];

  const [loading, setLoading] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [pagination, setPagination] = useState({
    showTotal: true,
    total: 10,
    pageSize: 10,
    current: 1,
    showJumper: true,
    sizeCanChange: true,
    pageSizeChangeResetCurrent: true,
  });
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [user, authLoading] = useAuthState(auth);
  const [epStatus, setEpStatus] = useState(EpStatus.ALL);
  const [sortByTime, setSortByTime] = useState(true);
  const dispatch = useDispatch();

  const trackJob = (record) => {
    logEvent(analytics, "track_job", {
      clicked_job: `${record.company} ${record.title}`,
    });
  };

  const loadJobs = () => {
    if (authLoading) return;
    setLoading(true);
    let payload = { posted: sortByTime };
    if (user) {
      payload = { ...payload, user_id: user.uid };
    }
    if (searchCompanies.length > 0) {
      handleSearch(searchCompanies);
    } else {
      axios
        .get(searchURL, {
          params: payload,
        })
        .then((res) => {
          const filterdData = res.data.filter((e) => e.availability);
          setAllData(filterdData);
          setData(filterdData);
          dispatch(updateNumOfNewGradJobs(filterdData.length));
          setPagination({ ...pagination, total: filterdData.length });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const onChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    setLoading(true);
    setTimeout(() => {
      setData(allData.slice((current - 1) * pageSize, current * pageSize));
      setPagination((pagination) => ({ ...pagination, current, pageSize }));
      setLoading(false);
    }, 300);
  };

  const handleSearch = (options, epStatus) => {
    if (authLoading) return;
    setLoading(true);
    let payload = { posted: sortByTime };
    if (options.length > 0) {
      payload = {
        ...payload,
        companies: options.toString().replace(/%20/g, "+"),
      };
    }
    if (user) {
      payload = { ...payload, user_id: user.uid };
    }
    if (epStatus !== EpStatus.ALL) {
      payload = { ...payload, ep: epStatus === EpStatus.EP };
    }
    if (searchKeywords) {
      payload = { ...payload, title: searchKeywords };
    }
    axios
      .get(searchURL, {
        params: payload,
      })
      .then((res) => {
        const filterdData = res.data.filter((e) => e.availability);
        setAllData(filterdData);
        setData(filterdData);
        setPagination({ ...pagination, total: filterdData.length, current: 1 });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const trackPageVisit = () => {
    logEvent(analytics, "page_visit", {
      user_info: `${user?.displayName} ${user?.email}`,
    });
  };

  useEffect(() => {
    trackPageVisit();
    loadJobs();
  }, [authLoading, user]);

  useEffect(() => {
    if (authLoading) return;
    const optionsURL = `${baseURL}/get/companies`;
    setOptionsLoading(true);
    axios
      .get(optionsURL)
      .then((res) => {
        setOptions(res.data.map((e) => e.name));
        dispatch(updateNumOfCompanies(res.data.length));
        setOptionsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOptionsLoading(false);
      });
  }, [authLoading, user]);

  const debouncedHandleSearch = debounce(handleSearch, 200);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title
        className="min-w-fit text-center"
        heading={4}
        style={{ wordBreak: "keep-all", marginBottom: "24px" }}
      >
        {isIntern
          ? "Search SWE Internship Positions for "
          : "Search SWE New Grad Positions for "}
      </Title>
      <div className="mb-5 flex justify-center items-center flex-col sm:flex-row">
        <InputSearch
          allowClear
          placeholder="Search for job titles"
          style={{ width: 250 }}
          defaultValue={searchKeywords}
          onChange={(value) => {
            setSearchKeywords(value);
          }}
        />
        <Select
          className="mt-3 sm:mt-0 ml-0 sm:ml-3"
          mode="multiple"
          placeholder="Search for companies"
          style={{ width: 250 }}
          allowClear
          onChange={(value) => setSearchCompanies(value)}
          loading={optionsLoading}
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <div className="flex mt-3 sm:mt-0 justify-center items-center">
          {!isIntern && (
            <Group
              type="button"
              className="ml-0 sm:ml-3"
              options={[EpStatus.ALL, EpStatus.EP, EpStatus.NO_EP]}
              defaultValue={epStatus}
              onChange={(option) => setEpStatus(option)}
            />
          )}
          <Switch
            checkedText="New Jobs"
            uncheckedText="All Jobs"
            className="ml-3"
            defaultChecked={sortByTime}
            onChange={(checked) => setSortByTime(checked)}
          />
        </div>
        <Button
          type="primary"
          className="mt-3 sm:mt-0 ml-3"
          onClick={() => {
            debouncedHandleSearch(searchCompanies, epStatus);
          }}
          loading={loading}
        >
          Search
        </Button>
      </div>
      <Table
        className="hidden sm:block"
        loading={loading}
        columns={columns}
        data={data}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
      />
      <div className="sm:hidden">
        <List
          size="small"
          loading={loading}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          render={(jobObject, index) => (
            <JobListItem
              jobObject={jobObject}
              index={index}
              key={index}
              isIntern={isIntern}
              loadJobs={loadJobs}
            />
          )}
        />
      </div>
    </div>
  );
};

export default JobTable;
