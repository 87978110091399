import {
  IconCheckCircleFill,
  IconCloseCircle,
} from "@arco-design/web-react/icon";
import { Colors } from "../../values/colors";
import Bookmark from "../jobList/Bookmark";
import { Link, List } from "@arco-design/web-react";
import ReportButtonMobile from "../jobList/ReportButtonMobile";

export function JobListItem({jobObject, index, loadJobs, isIntern, setDrawer1Visible}) {
  const listItemActions = [
      <ReportButtonMobile record={jobObject} isIntern={isIntern}/>

  ];
  const listItemMetaDescription = (
    <div>
      <div className="pl-1">{jobObject.company}</div>
      {!isIntern && (
        <div className="flex items-center">
          <span key={3} className="list-demo-actions-icon text-lg">
            {jobObject.ep ? (
              <IconCheckCircleFill style={{ color: Colors.green }} />
            ) : (
              <IconCloseCircle style={{ color: Colors.ep_grey }} />
            )}
          </span>
          Sponsor EP
        </div>
      )}
    </div>
  );
  return (
    <List.Item
      key={index}
      actions={listItemActions}
      extra={
        <div className="pt-[4px] pl-[4px]">
          <Bookmark
            isBookmarked={jobObject.bookmark}
            jobId={jobObject.id}
            reload={loadJobs}
          />
        </div>
      }
      actionLayout="vertical"
    >
      <List.Item.Meta
        title={
          <Link
            href={jobObject.link}
            target="_blank"
          >
            {jobObject.title}
          </Link>
        }
        description={listItemMetaDescription}
      />
    </List.Item>
  );
}
