export const BookmarkStatus = {
  INTERESTED: "Interested",
  APPLIED: "Applied",
  OA: "OA",
  INTERVIEWING: "Interviewing",
  OFFERED: "Offered",
  REJECTED: "Rejected",
};

export const EpStatus = {
  ALL: "All",
  EP: "EP",
  NO_EP: "No EP",
};

