import "./App.css";
import "@arco-design/web-react/dist/css/arco.css";
import { Route, Routes, Navigate } from "react-router-dom";
import NewGradPage from "./pages/newGrad/NewGradPage";
import InternshipPage from "./pages/internship/InternshipPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import BookmarkPage from "./pages/bookmark/BookmarkPage";
import AdminPage from "./pages/admin/AdminPage";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useLayoutEffect } from "react";
import GTMModal from "./gtm/gtmModal";

function App() {
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
  }, [loading])

  useLayoutEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      document.body.setAttribute('arco-theme', 'dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <div className="App dark:bg-slate-800">
      <NavBar />
      <GTMModal />
      <Routes>
        <Route path="/" element={<NewGradPage />} />
        <Route path="/internship" element={<InternshipPage />} />
        <Route path="/bookmark" element={user ? <BookmarkPage /> : <Navigate to="/" />} />
        <Route path="/admin" element={user ? <AdminPage /> : <Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
