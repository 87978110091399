import React from "react";
import JobList from "../jobList/JobList";
import { Alert } from "@arco-design/web-react";

const NewGradPage = () => {
  return (
    <div>
      <Alert
        banner
        type="info"
        showIcon
        closable={false}
        content="Dear international students, please help us by contributing EP sponsorship data, as obtaining this data is challenging yet essential for international CS students. Thank you!"
        style={{ marginTop: 60 }}
      />
      <div className="mt-[15px]">
        <JobList isIntern={false} />
      </div>
    </div>
  );
};

export default NewGradPage;
