import { useState } from "react";
import { compareSemVer } from "semver-parser";
import versionData from "./version.json";
import { Button, Modal } from "@arco-design/web-react";

const useCheckLocalStorageVersion = () => {
  const VERSION_KEY = "VERSION";
  const localVersion = localStorage.getItem(VERSION_KEY);
  const initialVersion = "1.0.0";
  const versionToSet = localVersion === null ? initialVersion : localVersion;

  const [version, setVersion] = useState(versionToSet);
  const setLocalVersion = (newVersion) => {
    localStorage.setItem(VERSION_KEY, newVersion);
    setVersion(newVersion)
  };
  return [version, setLocalVersion];
};

const useGetUpdates = () => {
  const [localVersion, setLocalVersion] = useCheckLocalStorageVersion();
  const latestVersion = versionData.version;

  const updateVersion = () => {
    setLocalVersion(latestVersion);
  };

  compareSemVer(latestVersion, localVersion);
  // if there is an update
  if (compareSemVer(latestVersion, localVersion) === 1) {
    const update = versionData.history[versionData.history.length - 1];
    return [update, updateVersion];
  }
  return [null, updateVersion];
};

const GTMModal = () => {
  const [versionUpdate, updateVersion] = useGetUpdates();
  const [visible, setVisible] = useState(versionUpdate !== null);

  const onDismiss = () => {
    setVisible(false);
    updateVersion();
  };

  return (
    visible && (
      <Modal
        title="🌟 What's New 🌟"
        visible={visible}
        onOk={onDismiss}
        onCancel={onDismiss}
        footer={<div><Button type="primary" onClick={onDismiss}>Got it</Button></div>}
        style={{width: "80%", maxWidth: 450 }}
      >
        <h3 className="mb-3" style={{ whiteSpace: 'pre-line'}}>{versionUpdate.description}</h3>
        <ul>
          {versionUpdate.updates.map((updateMsg, i) => (
            <div className="flex">
              <div className="mr-1">✨ </div>
              <li key={i}>{updateMsg}</li>
            </div>
          ))}
        </ul>
      </Modal>
    )
  );
};

export default GTMModal;
