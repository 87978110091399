import React from "react";
import {
  IconCodeBlock,
  IconCode,
  IconMenu,
  IconUser,
} from "@arco-design/web-react/icon";
import {
  Button,
  Typography,
  Image,
  Popover,
  Avatar,
  Drawer,
} from "@arco-design/web-react";
import { NavLink } from "react-router-dom";
import { Colors } from "../values/colors";
import { signInWithGoogle, logout, auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userLogin, userLogout } from "../reduxSlice/userSlice";
import "./styles.css";
// import { isDarkMode } from "../theme";
import { ThemeSwitcher } from "./ThemeSwitchButton";
import { useIsDarkMode } from "../theme";

const NavBar = () => {
  const [user, loading] = useAuthState(auth);
  const [username, setUsername] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const dispatch = useDispatch();
  const [isDark, setIsDark] = useIsDarkMode();

  useEffect(() => {
    if (loading) return;
    if (user) {
      dispatch(userLogin(JSON.stringify(user)));
      if (user.displayName.length >= 1) {
        setUsername(user.displayName.charAt(0).toUpperCase());
      }
    } else {
      dispatch(userLogout());
      setUsername("");
    }
  }, [user, loading, dispatch, isAdmin]);

  return (
    <header className="menu-demo">
      <div
        style={{
          display: "flex",
          padding: "5px 5px 5px 5px",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px",
          height: "60px",
          width: "100%",
          borderBottom: `1px solid ${Colors.lightGrey}`,
          background: isDark ? Colors.slate800 : "white",
          position: "fixed",
          top: "0px",
          zIndex: 5,
        }}
        className="dark:bg-slate-800 dark:text-white"
      >
        <IconMenu
          className="drawerBtn"
          onClick={() => {
            setDrawerVisible(true);
          }}
          style={{ fontSize: 24 }}
        />
        <NavLink
          to="/"
          className="logo flex items-center lg:fixed lg:start-[30px]"
        >
          <div className="hidden md:block">
            <Image
              style={{ pointerEvents: "none", borderRadius: "5px" }}
              width={50}
              src={"./CoverPage.png"}
              alt="logo"
            />
          </div>
          <Typography
            style={{
              fontWeight: "bold",
              marginLeft: 12,
            }}
          >
            Tech Job Repo
          </Typography>
        </NavLink>
        <NavLink
          to="/"
          className="hidden lg:block"
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              color: isActive ? Colors.primary : isDark ? "white" : "black",
              margin: "30px",
              textDecoration: "none",
            };
          }}
        >
          <div>
            <IconCodeBlock style={{ marginRight: "10px" }} />
            Software New Grad
          </div>
        </NavLink>
        <NavLink
          to="/internship"
          className="hidden lg:block"
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              color: isActive ? Colors.primary : isDark ? "white" : "black",
              margin: "30px",
              textDecoration: "none",
            };
          }}
        >
          <div>
            <IconCode style={{ marginRight: "10px" }} />
            Software Internship
          </div>
        </NavLink>
        <Drawer
          width={332}
          height={332}
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{ pointerEvents: "none", borderRadius: "5px" }}
                width={40}
                src={"./CoverPage.png"}
                alt="logo"
              />
              <Typography
                style={{
                  fontWeight: "bold",
                  marginLeft: 12,
                }}
              >
                Tech Job Repo
              </Typography>
            </div>
          }
          visible={drawerVisible}
          placement="left"
          onCancel={() => {
            setDrawerVisible(false);
          }}
          footer
        >
          <NavLink
            onClick={() => {
              setDrawerVisible(false);
            }}
            to="/"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? Colors.primary : isDark ? "white" : "black",
                margin: "30px",
                textDecoration: "none",
              };
            }}
          >
            <div>
              <IconCodeBlock style={{ marginRight: "10px" }} />
              Software New Grad
            </div>
          </NavLink>
          <NavLink
            onClick={() => {
              setDrawerVisible(false);
            }}
            to="/internship"
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? Colors.primary : isDark ? "white" : "black",
                margin: "30px",
                textDecoration: "none",
              };
            }}
          >
            <div>
              <IconCode style={{ marginRight: "10px" }} />
              Software Internship
            </div>
          </NavLink>
        </Drawer>
        {loading ? (
          ""
        ) : user ? (
          <div style={{ position: "fixed", right: 50 }}>
            <Popover
              position="bottom"
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <NavLink to="/bookmark">
                    <Button type="text">⭐ Bookmark</Button>
                  </NavLink>
                  {isAdmin && (
                    <NavLink to="/admin">
                      <Button type="text">🔐 Manage Jobs</Button>
                    </NavLink>
                  )}
                  <Button
                    type="text"
                    status="danger"
                    onClick={async () => logout()}
                  >
                    👋 Log out
                  </Button>
                </div>
              }
            >
              <Avatar size={32} style={{ backgroundColor: Colors.grey }}>
                {username}
              </Avatar>
            </Popover>
          </div>
        ) : (
          <div style={{ position: "fixed", right: 55 }}>
            <div className="sm:flex hidden">
              <Button type="outline" onClick={async () => signInWithGoogle()}>
                Login
              </Button>
            </div>
            <div className="sm:hidden">
              <Button
                shape="circle"
                type="outline"
                onClick={async () => signInWithGoogle()}
              >
                <IconUser className="" />
              </Button>
            </div>
          </div>
        )}
        <ThemeSwitcher
          onClick={() => {
            setIsDark(!isDark);
          }}
          style={{ position: "fixed", right: 10 }}
        />
      </div>
    </header>
  );
};

export default NavBar;
