import React from "react";
import { Colors } from "../values/colors";
import { Link } from "@arco-design/web-react";
import "../index.css";

const Footer = () => {
  return (
    <div className="flex-col justify-center items-center text-center p-10 text-gray-500 dark:text-slate-400">
      <p>
        Protip: Press <kbd>X</kbd> to switch theme.
      </p>
      <p>
        Found a new job opening or have feedback for us?{" "}
        <Link
          href={process.env.REACT_APP_GOOGLE_FORM_FEEDBACK_URL}
          target="_blank"
        >
          Tell us now!
        </Link>
      </p>
     
      <p className="pt-4">Buying me a coffee keeps me motivated on the project!</p>
      <p>And you get to view more jobs from even more companies :P</p>
      <a
        href="https://www.buymeacoffee.com/wangruofanu"
        target="_blank"
        className="flex-row justify-center"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
          alt="Buy Me A Coffee"
          style={{ height: 50, width: 150, display: "inline-flex" }}
          className="pt-2"
        />
      </a>
      <p className="pt-8">Copyright @ 2023 Tech Job Repo. Made with 💗 from Singapore.</p>
    </div>
  );
};

export default Footer;
