import React from "react";
import {
  Table,
  Select,
  Typography,
  Button,
  Link,
  Message,
  Alert,
  List,
} from "@arco-design/web-react";
import {
  IconLink,
  IconCheckCircleFill,
  IconCloseCircle,
  IconCloseCircleFill,
} from "@arco-design/web-react/icon";
import Bookmark from "../jobList/Bookmark";
import ReportButton from "../jobList/ReportButton";
import { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { BookmarkStatus } from "../../values/const";
import { Colors } from "../../values/colors";
const Option = Select.Option;

const BookmarkPage = () => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const options = Object.keys(BookmarkStatus).map((key) => BookmarkStatus[key]);
  const [pagination, setPagination] = useState({
    showTotal: true,
    total: 10,
    pageSize: 10,
    current: 1,
    showJumper: true,
    sizeCanChange: true,
    pageSizeChangeResetCurrent: true,
  });

  const onChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    setLoading(true);
    setTimeout(() => {
      setData(allData.slice((current - 1) * pageSize, current * pageSize));
      setPagination((pagination) => ({ ...pagination, current, pageSize }));
      setLoading(false);
    }, 1000);
  };
  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      width: 150,
      render: (col) => <div style={{ wordBreak: "keep-all" }}>{col}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      render: (col, record) => (
        <div style={{ minWidth: 150, wordBreak: "keep-all" }}>
          <Link href={record.link} target="_blank">
            {col}
          </Link>
        </div>
      ),
    },
    {
      title: "Job Type",
      dataIndex: "type",
      render: (col) => (
        <div style={{ wordBreak: "keep-all" }}>
          {col === "INTERN" ? "Internship" : "Fresh Grad"}
        </div>
      ),
      width: 150,
    },
    {
      title: "Sponsor EP?",
      dataIndex: "ep",
      render: (col, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record.type === "INTERN" ? (
            <></>
          ) : col ? (
            <Typography style={{ fontSize: 20 }}>✅</Typography>
          ) : (
            <Typography style={{ fontSize: 20 }}>❌</Typography>
          )}
        </div>
      ),
      width: 100,
    },
    {
      title: "Apply Link",
      dataIndex: "availability",
      render: (col, record) =>
        col ? (
          <Link hoverable={false} href={record.link} target="_blank">
            <Button type="outline" status="success">
              Apply Now
            </Button>
          </Link>
        ) : (
          <Button type="secondary" disabled>
            Closed
          </Button>
        ),
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (col, record) => (
        <Select
          placeholder="Select status"
          style={{ width: 130 }}
          defaultValue={col}
          onChange={(val) => updateStatus({ jobId: record.id, status: val })}
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
      width: 100,
    },
    {
      title: "Bookmark",
      dataIndex: "isBookmarked",
      render: (_, record) => (
        <Bookmark
          isBookmarked={true}
          jobId={record.id}
          reload={loadBookmarkedJobs}
        />
      ),
      width: 100,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => <ReportButton record={record} />,
      width: 10,
    },
  ];
  const [user, authLoading] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [username, setUsername] = useState("");

  const loadBookmarkedJobs = () => {
    if (authLoading || !user) return;
    setLoading(true);
    setUsername(user.displayName);
    const bookmarkURL = `${baseURL}/get/jobs/user/${user.uid}`;
    axios
      .get(bookmarkURL)
      .then((res) => {
        if (res.status === 200) {
          setAllData(res.data);
          setData(res.data);
          setTotal(res.data.length);
          setPagination({ ...pagination, total: res.data.length });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateStatus = ({ jobId, status }) => {
    if (authLoading || !user) return;
    setLoading(true);
    const url = `${baseURL}/upsert/bookmark`;
    const payload = {
      user_id: user.uid,
      job_id: jobId,
      status: status,
    };
    axios
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 201) {
          loadBookmarkedJobs();
          Message.success("Status updated successfully!");
        } else {
          Message.error("Update failed. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
        Message.error("Update failed. Please try again.");
      });
  };

  useEffect(() => {
    loadBookmarkedJobs();
  }, [user, authLoading]);

  function renderListItem(loadJobs) {
    return (jobObject, index) => {
      const listItemActions = [
        <Bookmark
          key={1}
          isBookmarked={true}
          jobId={jobObject.id}
          reload={loadJobs}
        />,
      ];
      const listItemMetaDescription = (
        <div>
          <div className="pl-1">{jobObject.company}</div>
          {!(jobObject.type == "INTERN") && (
            <div className="flex items-center">
              <span key={3} className="list-demo-actions-icon text-lg">
                {jobObject.ep ? (
                  <IconCheckCircleFill style={{ color: Colors.green }} />
                ) : (
                  <IconCloseCircle style={{ color: Colors.ep_grey }} />
                )}
              </span>
              Sponsor EP
            </div>
          )}
          {!jobObject.availability && (
            <div className="flex items-center">
              <span className="list-demo-actions-icon text-lg">
                <IconCloseCircleFill style={{ color: "red" }} />
              </span>
              Closed
            </div>
          )}
        </div>
      );
      return (
        <List.Item
          key={index}
          actionLayout="vertical"
          actions={listItemActions}
          extra={
            <Select
              placeholder="Select status"
              style={{ maxWidth: 90 }}
              bordered={false}
              defaultValue={jobObject.status}
              onChange={(val) =>
                updateStatus({ jobId: jobObject.id, status: val })
              }
            >
              {options.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          }
        >
          <List.Item.Meta
            title={
              <Link href={jobObject.link} target="_blank">
                {jobObject.title}
              </Link>
            }
            description={listItemMetaDescription}
          />
        </List.Item>
      );
    };
  }

  return (
    <div
      className="mt-[60px] grid justify-items-center content-start h-screen"
    >
      <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                <Typography.Title>

                  Bookmarks 🔖
                </Typography.Title>
              </Typography>
      <Alert
        banner
        type={total > 0 ? "success" : "info"}
        showIcon
        title={total > 0 ? `Well done ${username}!`: `Hey ${username}`}
        content={`You have bookmarked ${total} jobs`}
        closable
        className="mx-5 my-5 max-w-lg"
      />
      <Table
        className="hidden md:block max-w-fit mt-5"
        loading={loading}
        columns={columns}
        data={data}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
      />
      <div className="md:hidden mt-5">
        <List
          loading={loading}
          dataSource={data}
          className="min-w-[300px]"
          pagination={{ pageSize: 5 }}
          render={renderListItem(loadBookmarkedJobs)}
        />
      </div>
    </div>
  );
};

export default BookmarkPage;
