import React from "react";
import { Tabs } from "@arco-design/web-react";
import EditTable from "./EditTable";
import EditTicket from "./EditTicket";

const TabPane = Tabs.TabPane;

const AdminPage = () => {
  return (
    <Tabs
      defaultActiveTab="1"
      tabPosition="left"
      style={{ margin: "50px", marginTop: "100px"}}
    >
      <TabPane key="1" title="Manage New Grad">
        <EditTable isIntern={false} />
      </TabPane>
      <TabPane key="2" title="Manage Internship">
        <EditTable isIntern={true} />
      </TabPane>
      <TabPane key="3" title="Manage Tickets">
        <EditTicket />
      </TabPane>
    </Tabs>
  );
};

export default AdminPage;
