import React, { useEffect } from "react";
import {
  Button,
  Form,
  Message,
  Drawer,
  Modal
} from "@arco-design/web-react";
import { IconQuestionCircle } from "@arco-design/web-react/icon";
import { useSelector } from "react-redux";
import { signInWithGoogle } from "../../firebase";
import { useState } from "react";
import axios from "axios";
import "./styles.css"
import ReportEpForm from "../common/ReportEpForm";

const ReportButtonMobile = ({ record, isIntern }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const user = JSON.parse(useSelector((state) => state.user.user));
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [availLoading, setAvailLoading] = useState(false);
  const [form] = Form.useForm();
  const [visibleDrawer1, setVisibleDrawer1] = useState(false);
  const [visibleDrawer2, setVisibleDrawer2] = useState(false);
  const insertTicketURL = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/insert/ticket`;


  const handleOnEPClick = () => {
    if (isLogin) {
      setVisibleDrawer1(false)
      setVisibleDrawer2(true);
    } else {
      setVisible(true);
    }
  };

  const handleOnAvailClick = () => {
    if (isLogin) {
      setAvailLoading(true);
      const payload = {
        type: 'availability',
        user_id: user.uid,
        job_id: record.id,
        reason: `${record.availability ? "Job Closed" : "Open"}`,
      };
      axios
        .post(insertTicketURL, payload)
        .then((resp) => {
          if (resp.status === 201) {
            Message.success("Your response has been submitted successfully!");
          } else {
            Message.error("Submission failed. Please try again.");
          }
          setAvailLoading(false);
        })
        .catch((err) => {
          console.log(err);
          Message.error("Submission failed. Please try again.");
          setAvailLoading(false);
        });
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue(record);
  }, [form, record]);

  const onOk = () => {
    form
      .validate()
      .then((res) => {
        setConfirmLoading(true);
        const payload = {
          type: 'ep',
          user_id: user.uid,
          job_id: record.id,
          reason: `${res.ep ? "Sponsor" : "No Sponsorship"}: ${res.reason}`,
        };
        axios
          .post(insertTicketURL, payload)
          .then((resp) => {
            if (resp.status === 201) {
              Message.success("Your response has been submitted successfully!");
              setVisibleDrawer2(false);
              form.clearFields(["reason"]);
            } else {
              Message.error("Submission failed. Please try again.");
            }
            setConfirmLoading(false);
          })
          .catch((err) => {
            console.log(err);
            Message.error("Submission failed. Please try again.");
            setConfirmLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  return (
    <div className="list-demo-actions-icon">
      <IconQuestionCircle className="text-lg" onClick={() => {
        setVisibleDrawer1(true)
      }}/>
      <Modal
        title="❗ Report Issue ❗"
        visible={visible}
        onOk={async () => {
          setVisible(false);
          await signInWithGoogle();
        }}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
        style={{width: "60%"}}
      >
        <p>
          Dear struggling CS student, <br />
          Please log in to report the issue, we will try to resolve it as soon
          as possible! 👩‍💻
        </p>
      </Modal>
      <Drawer
        id="report-issue-drawer"
        height={150}
        title="❗ Report Issue ❗"
        placement="bottom"
        footer={null}
        visible={visibleDrawer1}
        onOk={() => {
          setVisibleDrawer1(false);
        }}
        onCancel={() => {
          setVisibleDrawer1(false);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column"}}>
            {!isIntern && <Button type="text" onClick={handleOnEPClick}>
              Incorrect EP Sponsorship
            </Button>}
            <Button
              type="text"
              onClick={handleOnAvailClick}
              loading={availLoading}
            >
              Incorrect availability
            </Button>
          </div>
      </Drawer>
      <Drawer
        height={500}
        placement="bottom"
        title="❗ Report Incorrect EP Sponsorship ❗"
        visible={visibleDrawer2}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisibleDrawer2(false)}
      >
        <ReportEpForm form={form} record={record}/>
      </Drawer>
    </div>
  );
};

export default ReportButtonMobile;
