import JobList from "../jobList/JobList";

const InternshipPage = () => {
  return (
    <div className="pt-[90px] sm:pt-[135px]">
      <JobList isIntern={true} />
    </div>
  );
};

export default InternshipPage;
