import React, { useState, useEffect } from "react";
import { isDarkMode } from "../theme";
import { Button, Notification, Popover, Tooltip } from "@arco-design/web-react";
import { IconMoonFill, IconSunFill } from "@arco-design/web-react/icon";
import { useHotkeys } from "react-hotkeys-hook";

export const ThemeSwitcher = ({ style, onClick }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDark = isDarkMode();
    setDarkMode(isDark);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
    if (darkMode) {
      localStorage.setItem("theme", "dark");
      document.body.setAttribute("arco-theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
      document.body.removeAttribute("arco-theme");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  useHotkeys("x", () => {
    toggleDarkMode();
    onClick();
  });

  return (
    <Button
      style={style}
      shape="circle"
      size="large"
      icon={darkMode ? <IconSunFill /> : <IconMoonFill />}
      onClick={() => {
        toggleDarkMode();
        onClick();
      }}
      className="px-4 py-2 rounded-md bg-gray-800 text-white"
    ></Button>
  );
};
