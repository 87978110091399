import React from "react";
import { Form, Input, Select, Link } from "@arco-design/web-react";
import "../jobList/styles.css"

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const ReportEpForm = ({ form, record }) => {
  return (
    <div>
      <Form
        form={form}
        labelCol={{
          span: 4,
          style: {
            flexBasis: 90,
          },
        }}
        wrapperCol={{
          span: 20,
          style: { flexBasis: "calc(100% - 90px)" },
        }}
        requiredSymbol={false}
      >
        <FormItem label="Company" field="company">
          <div>{record.company}</div>
        </FormItem>
        <FormItem label="Title" field="title">
          <Link href={record.link} target="_blank">
            {record.title}
          </Link>
        </FormItem>
        <FormItem label="EP" field="ep" rules={[{ required: true }]}>
          <Select
            options={[
              { label: "Sponsor EP", value: true },
              { label: "No Sponsorship", value: false },
            ]}
          />
        </FormItem>
        <FormItem label="Reason" field="reason" rules={[{ required: true }]}>
          <TextArea style={{ minHeight: 64 }} />
        </FormItem>
      </Form>
    </div>
  );
};

export default ReportEpForm;
