import React, { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Select,
  Message,
  Link,
  Space,
} from "@arco-design/web-react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import axios from "axios";
const FormItem = Form.Item;

const BatchEditButton = ({ records, isIntern, reload }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const computeHash = httpsCallable(functions, "compute_hash");

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const onOk = () => {
    form
      .validate()
      .then((res) => {
        setConfirmLoading(true);

        const payload = records.map((record) => ({
          id: record.id,
          company: record.company,
          title: record.title,
          link: record.link,
          availability: res.availability,
          type: isIntern ? "INTERN" : "FRESH_GRAD",
          ep: isIntern ? true : res.ep,
        }));

        computeHash({}).then((result) => {
          const data = result.data;
          const hash = data.cipherhex;
          const url = `${baseURL}/update/jobs`;
          const headers = {
            "Content-Type": "application/json",
            "x-cipherhex": hash,
          };
          axios
            .post(url, payload, {
              headers: headers,
            })
            .then((resp) => {
              if (resp.status === 201) {
                Message.success(
                  "Your response has been submitted successfully!"
                );
                setVisible(false);
                form.resetFields();
              } else {
                Message.error("Submission failed. Please try again.");
              }
              setConfirmLoading(false);
              reload();
            })
            .catch((err) => {
              console.log(err);
              Message.error("Submission failed. Please try again.");
              setConfirmLoading(false);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        disabled={records.length <= 0}
      >
        Batch Edit
      </Button>
      <Modal
        title="Batch Edit Job"
        visible={visible}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
      >
        <Form
          {...formItemLayout}
          form={form}
          labelCol={{
            style: { flexBasis: 90 },
          }}
          wrapperCol={{
            style: { flexBasis: "calc(100% - 90px)" },
          }}
          requiredSymbol={false}
        >
          {records.map((record, idx) => (
            <div key={idx}>
              <FormItem label="Company">
                <div>{record.company}</div>
              </FormItem>
              <FormItem label="Title">
                <Link href={record.link} target="_blank">
                  {record.title}
                </Link>
              </FormItem>
            </div>
          ))}
          {!isIntern && (
            <FormItem label="EP" field="ep" rules={[{ required: true }]}>
              <Select
                options={[
                  { label: "Sponsor EP", value: true },
                  { label: "No Sponsorship", value: false },
                ]}
              />
            </FormItem>
          )}
          <FormItem
            label="Avail"
            field="availability"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default BatchEditButton;
