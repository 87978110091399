import React from "react";
import { Modal, Button, Message, Spin } from "@arco-design/web-react";
import { IconStar, IconStarFill } from "@arco-design/web-react/icon";
import { Colors } from "../../values/colors";
import { BookmarkStatus } from "../../values/const";
import { signInWithGoogle, auth } from "../../firebase";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import debounce from "lodash.debounce";
import axios from "axios";
import "./styles.css"

const Bookmark = ({ isBookmarked, jobId, reload }) => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const [user, authLoading] = useAuthState(auth);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnClick = () => {
    if (authLoading) return;
    if (user) {
      setLoading(true);
      if (isBookmarked) {
        const url = `${baseURL}/delete/bookmark`;
        const payload = {
          user_id: user.uid,
          job_id: jobId,
        };
        axios
          .delete(url, { data: payload })
          .then((resp) => {
            if (resp.status === 200) {
              reload();
              Message.success("Job removed from bookmark list successfully!");
            } else {
              Message.error("Update failed. Please try again.");
            }
          })
          .catch((err) => {
            console.log(err);
            Message.error("Update failed. Please try again.");
          });
      } else {
        const url = `${baseURL}/upsert/bookmark`;
        const payload = {
          user_id: user.uid,
          job_id: jobId,
          status: BookmarkStatus.INTERESTED,
        };
        axios
          .post(url, payload)
          .then((resp) => {
            if (resp.status === 201) {
              reload();
              Message.success("Job bookmarked successfully!");
            } else {
              Message.error("Update failed. Please try again.");
            }
          })
          .catch((err) => {
            console.log(err);
            Message.error("Update failed. Please try again.");
          });
      }
      setLoading(false);
    } else {
      setVisible(true);
    }
  };

  const debouncedHandleOnClick = debounce(handleOnClick, 200);

  return (
    <div className="flex justify-center"> 
      {isBookmarked ? (
        <div className="list-demo-actions-icon" >
          {loading ? (
            <Spin />
          ) : (
            <IconStarFill onClick={debouncedHandleOnClick} className="text-xl" style={{ color: Colors.yellow}} />
          )}
        </div>
      ) : (
        <div className="list-demo-actions-icon" >
          {loading ? (
            <Spin />
          ) : (
            <IconStar onClick={debouncedHandleOnClick} className="text-xl" style={{ color: Colors.yellow}} />
          )}
        </div>
      )}
      <Modal
        title="⭐ Login to Bookmark ⭐"
        visible={visible}
        onOk={async () => {
          setVisible(false);
          await signInWithGoogle();
        }}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
        style={{ width: 300 }}
      >
        <p>
          Dear struggling CS student, <br />
          Please log in to bookmark your dream job and track your application
          progress! 🤩
        </p>
      </Modal>
    </div>
  );
};

export default Bookmark;
