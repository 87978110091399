import { createSlice } from "@reduxjs/toolkit";

const adminUid = process.env.REACT_APP_ADMIN_UID;

const initialState = {
  user: null,
  isLogin: false,
  isAdmin: false,
  numOfNewGradJobs: 0,
  numOfInternships: 0,
  numOfCompanies: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
      const userObj = JSON.parse(action.payload);
      const uid = userObj.uid;
      if (uid === adminUid) {
        state.isAdmin = true;
      }
    },
    userLogout: (state) => {
      state.user = null;
      state.isLogin = false;
      state.isAdmin = false;
    },
    updateNumOfNewGradJobs: (state, action) => {
      state.numOfNewGradJobs = action.payload;
    },
    updateNumOfInternships: (state, action) => {
      state.numOfInternships = action.payload;
    },
    updateNumOfCompanies: (state, action) => {
      state.numOfCompanies = action.payload;
    },
  },
});

export const {
  userLogin,
  userLogout,
  updateNumOfNewGradJobs,
  updateNumOfInternships,
  updateNumOfCompanies,
} = userSlice.actions;

export default userSlice.reducer;
