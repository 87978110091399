import React, { useEffect } from "react";
import { useState } from "react";
import {
  Table,
  Select,
  Typography,
  Button,
  Link,
  Switch,
} from "@arco-design/web-react";
import EditButton from "./EditButton";
import axios from "axios";
import {
  updateNumOfNewGradJobs,
  updateNumOfCompanies,
} from "../../reduxSlice/userSlice";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import BatchEditButton from "./BatchEditButton";
import Group from "@arco-design/web-react/es/Radio/group";
import { EpStatus } from "../../values/const";

const Option = Select.Option;
const Title = Typography.Title;

const EditTable = ({ isIntern }) => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const searchURL = isIntern
    ? `${baseURL}/get/jobs/INTERN`
    : `${baseURL}/get/jobs/FRESH_GRAD`;
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [showClosedJobs, setShowClosedJobs] = useState(false);
  const [epStatus, setEpStatus] = useState(EpStatus.ALL);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    showTotal: true,
    total: 10,
    pageSize: 10,
    current: 1,
    showJumper: true,
    sizeCanChange: true,
    pageSizeChangeResetCurrent: true,
  });
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [user, authLoading] = useAuthState(auth);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const loadJobs = () => {
    if (authLoading) return;
    setLoading(true);
    let payload = {};
    if (user) {
      payload = { user_id: user.uid };
    }
    if (searchCompanies.length > 0) {
      handleSearch(searchCompanies, epStatus);
    } else {
      axios
        .get(searchURL, {
          params: payload,
        })
        .then((res) => {
          const filterData = showClosedJobs
            ? res.data.filter((job) => !job.availability)
            : res.data;
          setAllData(filterData);
          setData(filterData);
          dispatch(updateNumOfNewGradJobs(filterData.length));
          setPagination({ ...pagination, total: filterData.length });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      width: 150,
      render: (col) => <div style={{ wordBreak: "keep-all" }}>{col}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      render: (col, record) => (
        <div style={{ minWidth: 150, wordBreak: "keep-all" }}>
          <Link href={record.link} target="_blank">
            {col}
          </Link>
        </div>
      ),
    },
    isIntern
      ? {}
      : {
          title: "Sponsor EP?",
          dataIndex: "ep",
          render: (col) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {col ? (
                <Typography style={{ fontSize: 20 }}>✅</Typography>
              ) : (
                <Typography style={{ fontSize: 20 }}>❌</Typography>
              )}
            </div>
          ),
          width: 100,
        },
    {
      title: "Apply Link",
      dataIndex: "availability",
      render: (col, record) =>
        col ? (
          <Link hoverable={false} href={record.link} target="_blank">
            <Button type="outline" status="success">
              Apply Now
            </Button>
          </Link>
        ) : (
          <Button type="secondary" disabled>
            Closed
          </Button>
        ),
      width: 100,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => (
        <EditButton record={record} isIntern={isIntern} reload={loadJobs} />
      ),
      width: 10,
    },
  ];

  const onChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    setLoading(true);
    setTimeout(() => {
      setData(allData.slice((current - 1) * pageSize, current * pageSize));
      setPagination((pagination) => ({ ...pagination, current, pageSize }));
      setLoading(false);
    }, 1000);
  };

  const handleSearch = (options, epStatus) => {
    if (authLoading) return;
    setLoading(true);
    let payload = {};
    if (options.length > 0) {
      payload = { companies: options.toString().replace(/%20/g, "+") };
    }
    if (user) {
      payload = { ...payload, user_id: user.uid };
    }
    if (epStatus !== EpStatus.ALL) {
      payload = { ...payload, ep: epStatus === EpStatus.EP };
    }
    axios
      .get(searchURL, {
        params: payload,
      })
      .then((res) => {
        const filterData = showClosedJobs
          ? res.data.filter((job) => !job.availability)
          : res.data;
        setAllData(filterData);
        setData(filterData);
        setPagination({ ...pagination, total: filterData.length, current: 1 });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadJobs();
  }, [authLoading, user]);

  useEffect(() => {
    if (showClosedJobs) {
      const closedJobList = allData.filter((job) => !job.availability);
      setData(closedJobList);
      setData(closedJobList);
      setPagination({ ...pagination, total: closedJobList.length, current: 1 });
    } else {
      loadJobs();
    }
  }, [showClosedJobs]);

  useEffect(() => {
    if (authLoading) return;
    const optionsURL = `${baseURL}/get/companies`;
    setOptionsLoading(true);
    axios
      .get(optionsURL)
      .then((res) => {
        setOptions(res.data.map((e) => e.name));
        dispatch(updateNumOfCompanies(res.data.length));
        setOptionsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOptionsLoading(false);
      });
  }, [authLoading, user]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="md:flex items-center">
        <Title
          className="min-w-fit text-center"
          heading={4}
          style={{ wordBreak: "keep-all", marginBottom: "24px" }}
        >
          {isIntern
            ? "Search SWE Internship Positions for "
            : "Search SWE New Grad Positions for "}
        </Title>
      </div>
      <div className="mb-5">
        <Select
          mode="multiple"
          placeholder="Search for companies"
          style={{ width: 345 }}
          allowClear
          onChange={(value) => setSearchCompanies(value)}
          loading={optionsLoading}
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        {!isIntern && (
          <Group
            type="button"
            className="ml-3"
            options={[EpStatus.ALL, EpStatus.EP, EpStatus.NO_EP]}
            defaultValue={epStatus}
            onChange={(option) => setEpStatus(option)}
          />
        )}
        <Button
          type="primary"
          className="ml-3"
          onClick={() => {
            handleSearch(searchCompanies, epStatus);
          }}
          loading={loading}
        >
          Search
        </Button>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <div className="mr-4">
          <Switch
            checkedText="Closed"
            uncheckedText="All Jobs"
            checked={showClosedJobs}
            onChange={(checked) => setShowClosedJobs(checked)}
          />
        </div>
        <div className="mr-4">
          <BatchEditButton
            records={selectedRows}
            isIntern={isIntern}
            reload={loadJobs}
          />
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
      />
    </div>
  );
};

export default EditTable;
