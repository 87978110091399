import React from "react";
import { Typography, Image, Statistic, Button } from "@arco-design/web-react";
import { IconArrowRise } from "@arco-design/web-react/icon";
import { Colors } from "../../values/colors";
import JobTable from "./JobTable";
import { signInWithGoogle } from "../../firebase";
import { useSelector } from "react-redux";
import { isDarkMode } from "../../theme";

const Title = Typography.Title;

const JobList = ({ isIntern }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const newGradJobs = useSelector((state) => state.user.numOfNewGradJobs);
  const hiringCompanies = useSelector((state) => state.user.numOfCompanies);

  return (
    <div className="mx-5 dark:text-white dark:bg-slate-800">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="hidden sm:block">
          <Image
            style={{ pointerEvents: "none", borderRadius: "25px" }}
            className="w-[100px] sm:w-[300px]"
            src={"./CoverPage.png"}
            alt="logo"
          />
        </div>
        <div className="sm:hidden">
          <Image
            style={{ pointerEvents: "none", borderRadius: "10px" }}
            className="w-[100px] sm:w-[300px]"
            src={"./CoverPage.png"}
            alt="logo"
          />
        </div>
        <h1 className="text-center text-2xl sm:text-4xl font-bold break-keep">
          Tech Job Repo
        </h1>
        <h2
          style={{ wordBreak: "keep-all" }}
          className="text-center text-xl sm:text-3xl"
        >
          A Place for{" "}
          <u style={{ color: Colors.primary, fontWeight: "bold" }}>Students</u>{" "}
          to Find SWE Jobs in{" "}
          <span style={{ color: Colors.primary, fontWeight: "bold" }}>
            Singapore 🇸🇬
          </span>
        </h2>
        <div className="py-5 grid justify-items-center sm:flex sm:py-0">
          <Statistic
            title={isIntern ? "SWE Internships" : "New Grad SWE Jobs"}
            value={newGradJobs}
            countUp
            loading={hiringCompanies === 0}
            suffix={<IconArrowRise style={{ color: "#ee4d38" }} />}
            styleValue={{ display: "flex", justifyContent: "center" }}
            className="my-3 sm:my-8 mx-5"
          />
          <Statistic
            title="Hiring Companies"
            value={hiringCompanies}
            countUp
            loading={hiringCompanies === 0}
            style={{ color: isDarkMode() ? "white" : "black" }}
            suffix={<IconArrowRise style={{ color: "#ee4d38" }} />}
            styleValue={{ display: "flex", justifyContent: "center" }}
            className="my-3 sm:my-8 mx-5 "
          />
        </div>
        {!isLogin && (
          <Button
            type="primary"
            shape="round"
            onClick={async () => signInWithGoogle()}
          >
            Start job search now
          </Button>
        )}
        <Title heading={6}>
          <em className="dark:text-white">It's completely free!</em>
        </Title>
      </div>
      <div style={{ marginTop: "20px" }}>
        <JobTable isIntern={isIntern} />
      </div>
    </div>
  );
};

export default JobList;
