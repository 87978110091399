import React, { useEffect } from "react";
import {
  Button,
  Popover,
  Modal,
  Form,
  Message,
} from "@arco-design/web-react";
import { IconMoreVertical } from "@arco-design/web-react/icon";
import { useSelector } from "react-redux";
import { signInWithGoogle } from "../../firebase";
import { useState } from "react";
import axios from "axios";
import ReportEpForm from "../common/ReportEpForm";

const ReportButton = ({ record, isIntern }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const user = JSON.parse(useSelector((state) => state.user.user));
  const [visible, setVisible] = useState(false);
  const [epVisible, setEpVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [availLoading, setAvailLoading] = useState(false);
  const [form] = Form.useForm();
  const insertTicketURL = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/insert/ticket`;

  const handleOnEPClick = () => {
    if (isLogin) {
      setEpVisible(true);
    } else {
      setVisible(true);
    }
  };

  const handleOnAvailClick = () => {
    if (isLogin) {
      setAvailLoading(true);
      const payload = {
        type: 'availability',
        user_id: user.uid,
        job_id: record.id,
        reason: `${record.availability ? "Job Closed" : "Open"}`,
      };
      axios
        .post(insertTicketURL, payload)
        .then((resp) => {
          if (resp.status === 201) {
            Message.success("Your response has been submitted successfully!");
          } else {
            Message.error("Submission failed. Please try again.");
          }
          setAvailLoading(false);
        })
        .catch((err) => {
          console.log(err);
          Message.error("Submission failed. Please try again.");
          setAvailLoading(false);
        });
    } else {
      setVisible(true);
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  useEffect(() => {
    form.setFieldsValue(record);
  }, [form, record]);

  const onOk = () => {
    form
      .validate()
      .then((res) => {
        setConfirmLoading(true);
        const payload = {
          type: 'ep',
          user_id: user.uid,
          job_id: record.id,
          reason: `${res.ep ? "Sponsor" : "No Sponsorship"}: ${res.reason}`,
        };
        axios
          .post(insertTicketURL, payload)
          .then((resp) => {
            if (resp.status === 201) {
              Message.success("Your response has been submitted successfully!");
              setEpVisible(false);
              form.clearFields(["reason"]);

            } else {
              Message.error("Submission failed. Please try again.");
            }
            setConfirmLoading(false);
          })
          .catch((err) => {
            console.log(err);
            Message.error("Submission failed. Please try again.");
            setConfirmLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Popover
        position="tr"
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!isIntern && <Button type="text" onClick={handleOnEPClick}>
              Incorrect EP Sponsorship
            </Button>}
            <Button
              type="text"
              onClick={handleOnAvailClick}
              loading={availLoading}
            >
              Incorrect availability
            </Button>
          </div>
        }
      >
        <Button type="text">
          <IconMoreVertical style={{ color: "black" }} />
        </Button>
      </Popover>
      <Modal
        title="❗ Report Issue ❗"
        visible={visible}
        onOk={async () => {
          setVisible(false);
          await signInWithGoogle();
        }}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
      >
        <p>
          Dear struggling CS student, <br />
          Please log in to report the issue, we will try to resolve it as soon
          as possible! 👩‍💻
        </p>
      </Modal>
      <Modal
        title="❗ Report Incorrect EP Sponsorship ❗"
        visible={epVisible}
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setEpVisible(false)}
        autoFocus={false}
        focusLock={true}
      >
        <ReportEpForm form={form} record={record} />
      </Modal>
    </div>
  );
};

export default ReportButton;
